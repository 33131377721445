@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=PT+Sans+Narrow&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: 'Montserrat,PT Sans Narrow', "Montserrat", sans-serif;
}

body{
  background-color: #F2F2F2;
}